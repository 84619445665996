import React, { useState } from 'react';
import Autocomplete from "react-google-autocomplete";
import { constant } from '@app/utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { submitContactUsForm } from '../../services/get_a_quote';
import Cookies from 'js-cookie';
const LeadForm = (props: any) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const currentPage = location.pathname;
  const getPageName = currentPage.replace(/^\//, '');
  const pageName = getPageName.endsWith('/') ? getPageName.slice(0, -1) : getPageName;
  const [addressData, setAddressObj] = useState({ address: '', house: '', street: '', city: '', state: '', zipcode: '', country: '' });
  const addressChange = (place: any) => {
    {
      if (place.address_components) {
        let addressObj = { ...addressData };
        for (const component of place.address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case "street_number":
              {
                addressObj.address = `${component.long_name} ${addressObj.address}`;
                addressObj.house = component.long_name;
                break;
              }
            case "route":
              {
                addressObj.address += component.short_name;
                addressObj.street = component.short_name;
                break;
              }

            case "postal_code":
              addressObj.zipcode = `${component.long_name}${addressObj.zipcode}`;
              break;

            case "postal_code_suffix":
              addressObj.zipcode = addressObj.zipcode;
              break;

            case "locality":
              addressObj.city = component.long_name;
              break;

            case "sublocality_level_1":
              if (addressObj.city == "") {
                addressObj.city = component.long_name;
              }
              break;

            case "administrative_area_level_3":
              if (addressObj.city == "") {
                addressObj.city = component.long_name;
              }
              break;
            case "administrative_area_level_1":
              addressObj.state = component.short_name;
              break;
            case "country":
              addressObj.country = component.long_name;
              break;
          }
          setAddressObj(addressObj);
          handleChange({ target: { name: 'address', value: addressObj.address + ' ' + addressObj.city + ' ' + addressObj.state + ' ' + addressObj.zipcode } });
        }
      }
    }
  }
  const [initialValues, setInitialValues] = useState({ name: '', address: '', phone: '' }); 
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      phone: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Required'),
    }),
    onSubmit: (values) => {
      if (addressData.address == '') {
        toast.error('Invalid address, Please select valid address');
        return;
      }
      const objValue = {
        "Address1": addressData.address,
        "Caller": "website",
        "City": addressData.city,
        "Comment": "",
        "Email": "",
        "FirstName": values.name,
        "Keyword": Cookies.get('kw') ? Cookies.get('kw') : null,
        "LastName": "",
        "LeadSource": "21",
        "LedgerType": "",
        "Phone1": values.phone,
        "Pid": Cookies.get('pid') ? Cookies.get('pid') : null,
        "ResCom": "",
        "State": addressData.state,
        "SubSource": "34",
        "Zip": addressData.zipcode,
      };
      setLoading(true);
      submitContactUsForm(objValue).then(function (res) {
        const resp = res.data;
        setLoading(false);
        if (resp.Acknowledge >= 0) {
          navigate('/thank-you');
        } else {
          toast.error(resp.FullMessage !== 'undefined' ? resp.FullMessage : 'Validation error found!');
        }
      }).catch(err => {
        toast.error('Internal Server Error!' + err);
      });
    }
  });
  const changeAddress = (e: any) => {
    if (e.target.value != "") {
      handleChange({ target: 'address', value: e.target.value });
    } else {
      handleChange({ target: 'address', value: '' });
      setAddressObj({ address: '', house: '', street: '', city: '', state: '', zipcode: '', country: '' });
    }
  }
  return (
    <>
      <h3><span>Need more info?</span> Let us put you in touch with one of our experts.</h3>
      <form onSubmit={handleSubmit}>
        <div className="lead_form">
          <div className="form-group">
            <label htmlFor="inputName" className="sr-only">Name*</label>
            <Form.Control
              id="name"
              name="name"
              type="text"
              placeholder="Name *"
              onChange={handleChange}
              value={values.name}
              isValid={touched.name && !errors.name}
              isInvalid={touched.name && !!errors.name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress" className="sr-only">Address*</label>
            <Autocomplete
              apiKey={constant.GOOGLE_API_KEY}
              onPlaceSelected={addressChange}
              options={{
                types: ["address"],
                fields: ["address_components", "geometry"],
                componentRestrictions: { country: "us" },
              }}
              onChange={(e) => changeAddress(e)}
              className={`${touched.address && !!errors.address && "is-invalid"} ${touched.address && !errors.address && "is-valid"}  form-control`}
              placeholder='Address *'
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputPhone" className="sr-only">Phone Number*</label>
            <Form.Group>
              <InputMask
                mask="(999) 999-9999"
                placeholder="Phone Number *"
                id="phone"
                name="phone"
                onChange={handleChange}
                value={values.phone}
              >
                {inputProps => <Form.Control {...inputProps} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && !!errors.phone} />}
              </InputMask>
            </Form.Group>
          </div>
          <button type="submit" className="btn btn-primary red_btn" disabled={isLoading ? true : false}> {isLoading ? "Sending..." : "Submit"}</button>
        </div>
      </form>
      {pageName == "" ?
        <p className="small_txt">By submitting this form, you consent for Safe Circuits to use automated technology to contact you at the number provided about Safe Circuits offers. This consent is not required to make a purchase.</p>
        : ""
      }

    </>
  );
};

export default LeadForm;
