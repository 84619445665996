import axios from 'axios';
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';

export const GetAppSid = async () => {
  try {
    const postData = {
      "AppSid": "",
      "AppVersion": "-1",
      "Latitude": null,
      "Longitude": null,
      "DeviceToken": "",
      "IsKeepLogin": "true",
      "Password": constant.Appsid_Login_Password,
      "UserName": constant.Appsid_Login_Username
    };
    const response = await axios.post(constant.Appsid_Login_Url, postData);
    if (response.data && response.data.AppSid) {
      Cookies.set('AppSid', response.data.AppSid, { expires: 1 });
      return response.data.AppSid;
    }
  } catch (err) {
    console.log(err);
  }
};