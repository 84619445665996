import React, { useState,useEffect  } from 'react';
import PageTitleComponent from '../../components/PageTitleComponent';
import Autocomplete from "react-google-autocomplete";
import { constant } from '@app/utils/constant';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { submitContactUsForm } from '../../services/get_a_quote';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [addressData, setAddressObj] = useState({ address: '', house: '', street: '', city: '', state: '', zipcode: '', country: '' });
    const addressChange = (place: any) => {
        {
            if (place.address_components) {
                let addressObj = { ...addressData };
                for (const component of place.address_components) {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number":
                            {
                                addressObj.address = `${component.long_name} ${addressObj.address}`;
                                addressObj.house = component.long_name;
                                break;
                            }
                        case "route":
                            {
                                addressObj.address += component.short_name;
                                addressObj.street = component.short_name;
                                break;
                            }

                        case "postal_code":
                            addressObj.zipcode = `${component.long_name}${addressObj.zipcode}`;
                            break;

                        case "postal_code_suffix":
                            addressObj.zipcode = addressObj.zipcode;
                            break;

                        case "locality":
                            addressObj.city = component.long_name;
                            break;

                        case "sublocality_level_1":
                            if (addressObj.city == "") {
                                addressObj.city = component.long_name;
                            }
                            break;

                        case "administrative_area_level_3":
                            if (addressObj.city == "") {
                                addressObj.city = component.long_name;
                            }
                            break;
                        case "administrative_area_level_1":
                            addressObj.state = component.short_name;
                            break;
                        case "country":
                            addressObj.country = component.long_name;
                            break;
                    }
                    setAddressObj(addressObj);
                    handleChange({ target: { name: 'address', value: addressObj.address + ' ' + addressObj.city + ' ' + addressObj.state + ' ' + addressObj.zipcode } });
                }
            }
        }
    }
    const [initialValues, setInitialValues] = useState({ name: '', address: '', phone: '', message: '' });

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            address: Yup.string().required('Required'),
            phone: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Required'),
            message: Yup.string()
        }),
        onSubmit: (values) => {
            if (addressData.address == '') {
                toast.error('Invalid address, Please select valid address');
                return;
            }
            const objValue = {
                "Address1": addressData.address,
                "Caller": "ContactUs",
                "City": addressData.city,
                "Comment": values.message,
                "Email": "",
                "FirstName": values.name,
                "Keyword": "",
                "LastName": "",
                "LeadSource": "21",
                "LedgerType": "",
                "Phone1": values.phone,
                "Pid": "",
                "ResCom": "",
                "State": addressData.state,
                "SubSource": "34",
                "Zip": addressData.zipcode,
            };
            setLoading(true);
            console.log(objValue);
            submitContactUsForm(objValue).then(function (res) {
                const resp = res.data;
                setLoading(false);
                if (resp.Acknowledge >= 0) {
                    navigate('/thank-you');
                } else {
                    toast.error(resp.FullMessage !== 'undefined' ? resp.FullMessage : 'Validation error found!');
                }
            }).catch(err => {
                toast.error('Internal Server Error!' + err);
            });
        }
    });
    const changeAddress = (e: any) => {
        if (e.target.value != "") {
            handleChange({ target: 'address', value: e.target.value });
        } else {
            handleChange({ target: 'address', value: '' });
            setAddressObj({ address: '', house: '', street: '', city: '', state: '', zipcode: '', country: '' });
        }
    }
    return (
        <>
            <PageTitleComponent title="Contact us - Safe Circuits" />
            <section className="inner_hero_blk contact_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner_main_blk contact_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="sidebar_wrap">
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Safe Circuits Video</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap video_wrap">
                                            <video width="100%" height="auto" poster="../img/video-poster.png" controls preload="auto" playsInline>
                                                <source src="../img/safe-circuits-n.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Get More Info on Honeywell</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap honeywell_links_wrap">
                                            <ul>
                                                <li><a href="../img/5800-Series-Sensors-Evolution-of-Wireless-Brochure.pdf" target="_blank">Honeywell Wireless Transmitters</a></li>
                                                <li><a href="../img/Honeywell-Tuxedo.pdf" target="_blank">Honeywell Tuxedo</a></li>
                                                <li><a href="../img/Honeywell-VISTA-Plus-Series-Information-for-Security-Dealers.pdf" target="_blank">Honeywell VISTA</a></li>
                                                <li><a href="../img/Honeywell-Keypads.pdf" target="_blank">New 6100 Series Keypads</a></li>
                                                <li> <a href="../img/Honeywells-Keypad-Family.pdf" target="_blank">Keypads</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="inner_main_content">
                                <div className="main_content_blk contact_us_blk">
                                    <h4>For any issues or concerns, please complete the form below.</h4>
                                    <p>Your information will be sent to us and one of our Customer Care Representatives will contact you shortly.</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="contact_form">
                                            <div className="form-group">
                                                <label htmlFor="inputName" className="sr-only">Name*</label>
                                                <Form.Control
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Name *"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    isValid={touched.name && !errors.name}
                                                    isInvalid={touched.name && !!errors.name}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress" className="sr-only">Address*</label>
                                                <Autocomplete
                                                    apiKey={constant.GOOGLE_API_KEY}
                                                    onPlaceSelected={addressChange}
                                                    options={{
                                                        types: ["address"],
                                                        fields: ["address_components", "geometry"],
                                                        componentRestrictions: { country: "us" },
                                                    }}
                                                    onChange={(e) => changeAddress(e)}
                                                    className={`${touched.address && !!errors.address && "is-invalid"} ${touched.address && !errors.address && "is-valid"}  form-control`}
                                                    placeholder='Address *'
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputPhone" className="sr-only">Phone Number*</label>
                                                <Form.Group>
                                                    <InputMask
                                                        mask="(999) 999-9999"
                                                        placeholder="Phone Number *"
                                                        id="phone"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        value={values.phone}
                                                    >
                                                        {inputProps => <Form.Control {...inputProps} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && !!errors.phone} />}
                                                    </InputMask>
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="messageTextarea" className="sr-only">Message</label>
                                                <Form.Control
                                                    id="message"
                                                    name="message"
                                                    as="textarea"
                                                    placeholder="Message"
                                                    onChange={handleChange}
                                                    value={values.message}
                                                    isValid={touched.message && !errors.message}
                                                    isInvalid={touched.message && !!errors.message}
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary red_btn" disabled={isLoading ? true : false}> {isLoading ? "Sending..." : "Submit"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactUs;
