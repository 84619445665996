import axios from 'axios';
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';
import { GetAppSid } from './appsid';


export const getAccountDetails = async (LedgerNumber: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": "",
      "Latitude": "",
      "Longitude": "",
      "LedgerNumber": LedgerNumber
    };
    const response = await axios.post(constant.GetAccountDetails_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};


export const getPaymentForm = async () => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": "",
      "Latitude": "",
      "Longitude": "",
      "AppSource": "",
      "IsProd": constant.IsProd
    };
    const response = await axios.post(constant.GetPaymentForm_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const makePayment = async (params: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
    const postData = {
      "AppNamespace": "",
      "AppSid": AppSid,
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": "",
      "Latitude": "",
      "Longitude": "",
      "LedgerNumber": params.LedgerNumber,
      "LastFourDigits" : params.CardNumber,
      "ExpiryDate" : params.ExpiryDate,
      "Amount": params.Amount,
      "CardType" : params.CardType,
      "AutoPay": params.EnrollAutoPay,
      "CustomerRefNumber" : params.CustomerRefNumber
    };

    const response = await axios.post(constant.MakePayment_Api_Url, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const addPaymentMethod = async (params: any) => {
  try {
    let AppSid = Cookies.get('AppSid');
    if (!AppSid) {
      AppSid = await GetAppSid();
    }
 
    const postData = {
        "AppNamespace": "",
        "AppSid": AppSid,
        "AppVersion": "",
        "CheckVersion": "",
        "IpAddress": "",
        "Latitude": "",
        "Longitude": "",
        "LedgerNumber": params.LedgerNumber,
        "UID": params.paymentUid,
        "IsProd": constant.IsProd
      };

      const response = await axios.post(constant.AddPayment_Method_Api_Url, postData);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  export const sendEmailSms = async (params: any) => {
    try {
      let AppSid = Cookies.get('AppSid');
      if (!AppSid) {
        AppSid = await GetAppSid();
      }
  
      const postData = {
        "AppNamespace": "",
        "AppSid": AppSid,
        "AppVersion": "",
        "CheckVersion": "",
        "IpAddress": "",
        "Latitude": "",
        "Longitude": "",
        "PhoneNumber": params.Phone,
        "Email": params.Email
      };
  
      const response = await axios.post(constant.SendEmailSMS_Api_Url, postData);
      return response;
    } catch (err) {
      console.log(err);
    }
  };


