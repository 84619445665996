import React from 'react';
import Modal from 'react-bootstrap/Modal';

const AddNewCardMdl = (props) => {
  return (
    <Modal size="lg" className='new_card_popup' aria-labelledby="contained-modal-title-vcenter" show={props.addNewCardModalShow} onHide={() => props.setAddNewCardModalShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Credit Card Details</Modal.Title>
      </Modal.Header>
      <Modal.Body id="payment-mdl">
        <div className='saved_card_blk order_info_blk'>
          <h3 className='blk_title'>Credit Card Details</h3>
          <div id='secureFrameWrapper'>
            {(props.ispayLoading) ? 
                <div style={{ height: "350px"}} id="loadingpayment"
                    className="d-flex w-100 justify-content-center align-items-center"
                >
                  Loading payment form....
                </div>  
              :
              <iframe id="secureFrame" className="secureFrame credit_card" style={{ border:"0", height:"385px", width:"100%" }} src={props.paymentFormUrl}>
                    </iframe> 
            }
          </div>
          <div id="loader_container">
            
          </div>
          <div className="authorization_wrap">
            <div className="authorization_inner">
              <p>Authorized By</p>
              <ul>
                <li><img src="img/chase_payment.jpg" alt="Chase Payment" /></li>
                <li><img src="img/master_card.jpg" alt="Master Card" /></li>
                <li><img src="img/visa_card.jpg" alt="Visa" /></li>
                <li><img src="img/discover_icon.jpg" alt="Discover" /></li>
                <li><img src="img/american_express.jpg" alt="American Express" /></li>
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewCardMdl;
