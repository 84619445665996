import axios from 'axios';
import { constant } from '@app/utils/constant';

export const submitContactUsForm = async (data: any) => {
  try {
    let postData = {
      "AppNamespace": "",
      "AppSid": "",
      "AppVersion": "",
      "CheckVersion": "",
      "IpAddress": "",
      "Latitude": "",
      "Longitude": "",
      "AccountNumber": "",
      "Address1": data.Address1,
      "Address2": "",
      "ApptDate": "",
      "ApptTime": "",
      "AreaOfInterest": "",
      "Caller": data.Caller,
      "City": data.City,
      "Comment": data.Comment,
      "Email": data.Email,
      "EntryUser": "",
      "FirstName": data.FirstName,
      "Keyword": "",
      "LastName": "",
      "LeadSource": data.LeadSource,
      "LedgerType": "",
      "Phone1": data.Phone1,
      "Phone2": "",
      "Pid": "",
      "PromoCode": "",
      "ReferSource": "",
      "ResCom": "",
      "SalesRep": "",
      "SecurityIssue": "",
      "State": "",
      "SubSource": data.SubSource,
      "WebTrack": "",
      "Xid": "",
      "Zip": data.Zip,
    }
    const response = await axios.post(constant.POSTLEAD_API_URL, postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};






