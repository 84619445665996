import React, { useEffect } from 'react';
import PageTitleComponent from '../components/PageTitleComponent';
const HomeV2 = () => {
    useEffect(() => { 
        document.body.classList.add('home_v2');  
        return () => {
          document.body.classList.remove('home_v2');
        };
      }, []);
    return ( 
        <>
           <PageTitleComponent title="Safe circuits - Home Office Secruity" />
            <section className="hero_blk home2_hero_blk">
                <div className="home2_hero_inr_blk">
                    <div className="hero_content_wrap">
                        <div className="hero_content_left">
                            <div className="hero_txt_blk">
                                <h1><span>Security Systems</span> by <br/>Safe Circuits</h1>
                                <p>Residential and commercial alarms systems, video surveillance systems and Security systems installed in Dix Hills NY, Melville, Commack, and all of Nassau, Suffolk and Queens County.</p>
                                <a className="btn btn-primary red_btn" href="contact-us.html">Contact US</a>
                            </div> 
                        </div>
                        <div className="hero_content_right">
                            <img src="../img/home-2-hero-img.png" alt="Home"/>    
                        </div> 
                    </div>
                </div>
            </section>
            <section className="lead_form_blk">
            <div className="container">
                    <div className="row lead_form_inr">
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="form_left_blk">
                                <h2>Real Security <span>Powered by Real People</span></h2>
                                <p>Protect your home with the best in the business. Safe Circuits has been serving customers since many years.</p>
                                <ul>
                                    <li>Free equipment installed by a Safe Circuits Pro.</li>
                                    <li>Our Professional Monitoring Team protects your home around the clock.</li>
                                    <li>Reliable, in-house service technicians ensure your system stays up and running.</li>
                                    <li>One of a kind upgrade program keeps your system optimized with the latest technology at <strong>NO COST</strong>.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="lead_form_wrap">
                            <h3><span>Need more info?</span> Let us put you in touch with one of our experts.</h3>
                            <form>
                                <div className="lead_form">
                                <div className="form-group">
                                    <label for="inputName" className="sr-only">Name*</label>
                                    <input className="form-control" type="text" placeholder="Name*"/>
                                </div>
                                <div className="form-group">
                                    <label for="inputAddress" className="sr-only">Address*</label>
                                    <input type="text" className="form-control" placeholder="Address*"/>
                                </div>
                                <div className="form-group">
                                    <label for="inputPhone" className="sr-only">Phone Number*</label>
                                    <input type="tel" className="form-control" placeholder="Phone Number*"/>
                                </div>
                                <button type="submit" className="btn btn-primary red_btn">Submit</button>
                                </div>
                                <p className="small_txt">By submitting this form, you consent for Safe Circuits to use automated technology to contact you at the number provided about Safe Circuits offers. This consent is not required to make a purchase.</p>
                            </form>
                        </div>
                        </div>
                    </div>
            </div> 
            </section>
            <section className="services_blk home_security_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section_title">
                                <h2>Home <span>Security Systems</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_box_title">
                                        <div className="serv_icon">
                                            <img src="../img/video-camera.svg" alt="icon"/>
                                        </div>
                                        <h4>Video surveillance Systems</h4>
                                    </div>
                                    <div className="serv_content">
                                        <p>Video surveillance with DVR NVR systems and IP Camera  record all activity on your premises and allow you to remotely monitor your home and business from any computer, smart phone, or tablet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_box_title">
                                        <div className="serv_icon">
                                            <img src="../img/star-shield-icon.svg" alt="icon"/>
                                        </div>
                                        <h4>Alarm Systems</h4>
                                    </div>
                                    <div className="serv_content">
                                        <p>Residential and commercial alarm systems installed by our expert security system installers protect your home and business. Remote monitoring and armed responses to alarms available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_box_title">
                                        <div className="serv_icon">
                                            <img src="../img/circuit-icon.svg" alt="icon"/>
                                        </div>
                                        <h4>Get Automated Systems</h4>
                                    </div>
                                    <div className="serv_content">                                    
                                        <p>With modern technology from Safe Circuits, you can get automated systems installed in your property for convenience and safety. Turn on/off lights or change a thermostat’s temperature from your smartphone!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_box_title">
                                        <div className="serv_icon">
                                            <img src="../img/thumbs-up-icon.svg" alt="icon"/>
                                        </div>
                                        <h4>Work with the best</h4>
                                    </div>
                                    <div className="serv_content">                                    
                                        <p>With over 29 years of experience, we are the best in town for security systems. You can find competitive pricing and meticulous professional service, so get in touch with us to get protection for your properties.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="btn_wrap">
                                <a className="btn btn-primary red_btn" href="contact-us.html">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="customer_reviews_blk">
                <div className="container">                
                    <div className="row">
                        <div className="col-12">
                            <div className="section_title">
                                <h2>Safe Circuits <span>Customer Reviews</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="customer_review_wrap">
                                <div className="customer_review_img">
                                    <img src="../img/customer-review-img.png" alt="Customer Review"/>
                                </div>
                                <div className="customer_reviews">
                                    <div className="review_item">
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                        <div className="review_txt">
                                            <p>1 I've been a HAPPY Safe Circuits Alarm System customer for 17 years, and I still have the same salesman Steve W.!!!</p>
                                            <p className="author"><strong>Berkeley Heights, NJ</strong></p>
                                        </div>
                                    </div>
                                    <div className="review_item">
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                        <div className="review_txt">
                                            <p>2 I've been a HAPPY Safe Circuits Alarm System customer for 17 years, and I still have the same salesman Steve W.!!!</p>
                                            <p className="author"><strong>Berkeley Heights, NJ</strong></p>
                                        </div>
                                    </div>
                                    <div className="review_item">
                                        <div className="stars">
                                            <ul>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                                <li><i className="fas fa-star"></i></li>
                                            </ul>
                                        </div>
                                        <div className="review_txt">
                                            <p>3 I've been a HAPPY Safe Circuits Alarm System customer for 17 years, and I still have the same salesman Steve W.!!!</p>
                                            <p className="author"><strong>Berkeley Heights, NJ</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info_blk home2_info_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="info_ad_box">
                                <p><span>FREE</span> Monitored Home Security System!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeV2;
