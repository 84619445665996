import React,{useEffect} from 'react'; 
import PageTitleComponent from '../../components/PageTitleComponent';
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);  
    return (
        <>
         <PageTitleComponent title={"Privacy Policy - Safe Circuits"}/> 
           <main className="privacy-policy">            
            <section className="inner_hero_blk legal_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>Privacy Policy</h1>
                            </div> 
                        </div> 
                    </div>
                </div>
            </section>
            <section className="inner_main_blk legal_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="inner_main_content">
                                <div className="main_content_blk">
                                    <p>We are committed to protecting and respecting your privacy. This Privacy Policy and the Conditions of Use sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. For the purpose of European Union Data Protection legislation, We (as defined and detailed in this website (our Site)) are the data controller.</p>
                                    <h4>Information we may collect from you</h4>
                                    <p>We may collect and process the following data about you:</p>
                                    <ul>
                                        <li>Information that you provide by filling in forms on our Site. This includes information provided at the time of registering to use our Site, subscribing to our service, posting material or requesting further services. We may also ask you for information when you enter a competition or promotion sponsored by us, and when you report a problem with our Site.</li>
                                        <li>If you contact us, we may keep a record of that correspondence.</li>
                                        <li>We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.</li>
                                        <li>Details of transactions you carry out through our Site and of the fulfillment of your orders.</li>
                                        <li>Details of your visits to our Site including, but not limited to, traffic data, location data, weblogs and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.</li>
                                    </ul>
                                    <h4>IP Addresses</h4>
                                    <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>
                                    <h4>Cookies</h4>
                                    <p>Our Site uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our Site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie Policy.</p>
                                    <h4>Where we store your personal data</h4>
                                    <p>When we use your information as described in this Privacy Policy, this may occasionally involve sending your information around the world (and in particular where you are located within the European Economic Area (EEA), this includes sending it outside the EEA). By providing us with your personal information, you agree that we may transfer, store and process your information in this manner. Unfortunately, the transmission of information via the internet is not completely secure. Although we employ security measures designed to protect your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                                    <h4>Uses made of the information</h4>
                                    <p>We use information held about you in the following ways:</p>
                                    <ul>
                                        <li>To ensure that content from our Site is presented in the most effective manner for you and for your computer.</li>
                                        <li>To provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes.</li>
                                        <li>To carry out our obligations arising from any contracts entered into between you and us.</li>
                                        <li>To allow you to participate in interactive features of our service, when you choose to do so.</li>
                                        <li>To notify you about changes to our service.</li>
                                    </ul>
                                    <h4>Disclosure of your information</h4>
                                    <p>We may disclose your personal information to:</p>
                                    <ul>
                                        <li>any member of our Group of Companies (our subsidiaries, our ultimate holding company and its subsidiaries);</li>
                                        <li>data processing companies, mailing houses and other third parties working on our behalf (whether directly or indirectly).</li>
                                    </ul>
                                    <p>We may disclose your personal information to third parties:</p>
                                    <ul>
                                        <li>In the event that we sell or buy any business, assets or shares, in which case we may disclose your personal data to the prospective seller or buyer of such business, assets or shares.</li>
                                        <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions; or to protect our rights, property, or safety, or those of our customers or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
                                        <li>Every now and again, requests are received for information from government departments, the police and other enforcement agencies. If this happens, and there is a proper legal basis for providing your information, it will be provided to the organisation asking for it.</li>
                                    </ul>
                                    <h4>Access to information (this section only applies if We are based in the United Kingdom)</h4>
                                    <p>Data Protection legislation gives you the right to access information held about you. Your right of access can be exercised in accordance with the law. Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you.</p>
                                    <h4>Changes to our Privacy Policy</h4>
                                    <p>This Privacy Policy may be updated from time to time so you may want to check it each time you visit our Site.</p>
                                    <h4>Contact</h4>
                                    <p>Please contact us using the details shown on our Site if you have any questions, comments or requests regarding this</p>
                                    <p>Privacy Policy.</p>
                                    <p>© All rights reserved. September, 2013.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           </main>
        </>
    );
};

export default PrivacyPolicy;
