import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
const Header = () => {
  const location = useLocation();
  const currentPage = location.pathname;
  const [isOpen, setIsOpen] = useState(null);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const steDestopV = isOpen !== null ? (isOpen || window.screen.width > 991 ? 'block' : 'none') : "";

  const [isSticky, setSticky] = useState(false);
  const checkScrollPosition = () => {
    const sDistance = window.scrollY;
    if (sDistance > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      checkScrollPosition();
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const urlPath = window.location.pathname;
  if(urlPath != '/payment'){
    document.body.classList.remove('payment_summary');
    document.body.classList.remove('payment_link');
  }

  return (
    <>
      <header className={`header ${isSticky ? 'stickyHeader' : ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="logo_toggle_wrap">
                <div className="logo_wrap">
                  <a href="/">
                    <img src="../img/safe-circuits-logo.png" alt="Safe Circuits" />
                  </a>
                </div>
                <div className={`mobile_menu_toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9 menu_desktop" style={{ display: steDestopV }}>
              <div className="header_nav">
                <ul className="d-flex">
                  <li className={currentPage == "/alarm-systems" ? "active" : ""}><Link to={"/alarm-systems"}>Alarm Systems</Link></li>
                  <li className={currentPage == "/camera-systems" ? "active" : ""}><Link to={"/camera-systems"}>Camera Systems</Link></li>
                  <li className={currentPage == "/home-automation" ? "active" : ""}><Link to={"/home-automation"}>Home Automation</Link></li>
                  <li className={currentPage == "/contact-us" ? "active" : ""}><Link to={"/contact-us"}>Contact Us</Link></li>
                  <li className={currentPage == "/help" ? "active" : ""}><Link to={"/help"}>Help</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

    </>
  );
};

export default Header;
