import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'; 
import { toast } from 'react-toastify';

const AutoPayMdl = (props) => {
  const formRef = useRef(null);

  const handleAutoPaySubmitBtn= () => {  
    if (formRef.current.paymentType.value == "") {
      toast.error('Please Select Payment Type');
      return false;
    }else{
      props.handleAutoPayChange(formRef.current.paymentType.value)
      props.setEnrollAutopayModalShow(false);
    }
  }

  return (
    <Modal size="lg" className='autopay_popup' aria-labelledby="contained-modal-title-vcenter" show={props.enrollAutopayModalShow} onHide={() => props.setEnrollAutopayModalShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Enroll for AutoPay</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef}>
          <div className="form-group">
            <label htmlFor="paymentType">Payment Type</label>
            <Form.Select aria-label="Default select" name="paymentType" id="paymentType" onChange={() => props.handleChange}>
              <option value="">Select Payment Type</option>
              <option value="1">Payment Type 1</option>
              <option value="2">Payment Type 2</option>
              <option value="3">Payment Type 3</option>
            </Form.Select>
            <span className='small'>I authorize Slomin's, Inc. to automatically charge either the credit card or checking account shown above for all payments due on this account. This automatic charge shall continue until i decide to discontinue this payment option. If i decide to discontinue this option, i will give Slomin's thirty(30) days notice. I understand that Slomin's reserves the right, upon written notification to me, to terminate this automatic payment option, and/or my participation therein at anytime. My participation is subject to Slomin's continuing approval.</span>
          </div>
        </form>
        <div className='ok_btn_blk'>
          <button type="button" className="btn btn-primary red_outline_btn" onClick={() => { props.setEnrollAutopayModalShow(false); }}>Cancel</button>
          <button type="button" onClick={()=>{handleAutoPaySubmitBtn()}} className="btn btn-primary red_btn">Enroll</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AutoPayMdl;
