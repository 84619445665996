import React, { useEffect } from 'react';
import LeadForm from '../../components/common/LeadForm';
import PageTitleComponent from '../../components/PageTitleComponent';
const Help = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return (
        <>
            <PageTitleComponent title="How to replace a battery - Safe Circuits" />
            <section className="inner_hero_blk help_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>How to replace a battery</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner_main_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="sidebar_wrap">
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>FREE Monitored Home Security System!</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap">
                                            <LeadForm />
                                        </div>
                                        <div className="license_blk">
                                            <p><span>Licensed by N.Y.S. Department of State</span>: # 12000049143</p>
                                            <p><span>State of NJ</span>: # 34BA00181700</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Safe Circuits Video</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap video_wrap">
                                            <video width="100%" height="auto" poster="../img/video-poster.png" controls preload="auto" playsInline>
                                                <source src="../img/safe-circuits-n.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Get More Info on Honeywell</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap honeywell_links_wrap">
                                            <ul>
                                                <li><a href="../img/5800-Series-Sensors-Evolution-of-Wireless-Brochure.pdf" target="_blank">Honeywell Wireless Transmitters</a></li>
                                                <li><a href="../img/Honeywell-Tuxedo.pdf" target="_blank">Honeywell Tuxedo</a></li>
                                                <li><a href="../img/Honeywell-VISTA-Plus-Series-Information-for-Security-Dealers.pdf" target="_blank">Honeywell VISTA</a></li>
                                                <li><a href="../img/Honeywell-Keypads.pdf" target="_blank">New 6100 Series Keypads</a></li>
                                                <li> <a href="../img/Honeywells-Keypad-Family.pdf" target="_blank">Keypads</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="inner_main_content">
                                <div className="main_content_blk">
                                    <p>Most alarm systems use batteries, and batteries need to be replaced over a period of time. Replacing batteries on a regular schedule will ensure that your residential or commercial alarm systems will continue to securely protect you.</p>
                                    <p>Given below are videos to some common battery changes.</p>
                                </div>
                                <div className="help_vid_blk">
                                    <div className="vid_item">
                                        <h5>5811 window/door flat pack wireless transmitter</h5>
                                        <iframe width="100%" height={350} src="https://www.youtube.com/embed/bNIbnjkAij8?feature=oembed" frameBorder={0} allowFullScreen />
                                    </div>
                                    <div className="vid_item">
                                        <h5>5800 wireless motion sensor</h5>
                                        <iframe width="100%" height={350} src="https://www.youtube.com/embed/9J7tf0Z1_Kk?feature=oembed" frameBorder={0} allowFullScreen />
                                    </div>
                                    <div className="vid_item">
                                        <h5>5816 wireless window/door contact</h5>
                                        <iframe width="100%" height={350} src="https://www.youtube.com/embed/3fd2-IwRKcM?feature=oembed" frameBorder={0} allowFullScreen />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Help;
