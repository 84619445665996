import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

const UpdateAmountMdl = (props) => {
  const formRef = useRef(null);

  const handleUpdateAmtSubmitBtn= () => {  
    if (formRef.current.inputAmount.value == "" || formRef.current.inputAmount.value < 1) {
      toast.error('Please insert valid amount.');
      return false;
    }else{
      props.handleAmountChange(formRef.current.inputAmount.value)
      props.setUpdateAmountModalShow(false);
    }
  }
  
  return (
    <Modal size="md" className='update_amount_popup' aria-labelledby="contained-modal-title-vcenter" show={props.updateAmountModalShow} onHide={() => props.setUpdateAmountModalShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Payment Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef}>
          <div className="form-group">
            <label htmlFor="inputAmount">Amount to Pay</label>
            <input className="form-control" type="number" name="Amount" id="inputAmount" placeholder="Enter Amount" onChange={() => props.handleChange} />
          </div>
        </form>
        <div className='ok_btn_blk'>
          <button type="button" className="btn btn-primary red_outline_btn" onClick={() => { props.setUpdateAmountModalShow(false); }}>Cancel</button>
          <button type="button" className="btn btn-primary red_btn" onClick={() => { handleUpdateAmtSubmitBtn(); }}>Update</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAmountMdl;
