import React from 'react'; 
import FooterTextComponet from '../components/common/FooterTextComponet';
import LeadForm from '../components/common/LeadForm';
import PageTitleComponent from '../components/PageTitleComponent';
import { Link } from 'react-router-dom';
const Home = () => {
    return ( 
        <>
           <PageTitleComponent title="Safe circuits - Home Office Security" />
            <section className="hero_blk">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-9">
                            <div className="hero_txt_blk">
                                <h1>Security systems by Safe Circuits</h1>
                                <p>Residential and commercial alarms systems, video surveillance systems and Security systems installed in Dix Hills NY, Melville, Commack, and all of Nassau, Suffolk and Queens County</p>
                            </div>
                        </div>
                        <div className="col-md-3 contact_btn_wrap"> 
                         <Link to={"./contact-us"}><span><i className="fa fa-phone" />Contact US</span></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="lead_form_blk">
                <div className="container">
                    <div className="lead_form_wrap">
                        <LeadForm/>
                    </div>
                </div>
            </section>
            <section className="services_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_icon">
                                        <img src="../img/video-camera.svg" alt="icon" />
                                    </div>
                                    <div className="serv_content">
                                        <h4>Video surveillance Systems</h4>
                                        <p>Video surveillance with DVR NVR systems and IP Camera  record all activity on your premises and allow you to remotely monitor your home and business from any computer, smart phone, or tablet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_icon">
                                        <img src="../img/star-shield-icon.svg" alt="icon" />
                                    </div>
                                    <div className="serv_content">
                                        <h4>Alarm Systems</h4>
                                        <p>Residential and commercial alarm systems installed by our expert security system installers protect your home and business. Remote monitoring and armed responses to alarms available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_icon">
                                        <img src="../img/circuit-icon.svg" alt="icon" />
                                    </div>
                                    <div className="serv_content">
                                        <h4>Get Automated Systems</h4>
                                        <p>With modern technology from Safe Circuits, you can get automated systems installed in your property for convenience and safety. Turn on/off lights or change a thermostat’s temperature from your smartphone!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="serv_box">
                                <div className="serv_box_inr">
                                    <div className="serv_icon">
                                        <img src="../img/thumbs-up-icon.svg" alt="icon" />
                                    </div>
                                    <div className="serv_content">
                                        <h4>Work with the best</h4>
                                        <p>With over 29 years of experience, we are the best in town for security systems. You can find competitive pricing and meticulous professional service, so get in touch with us to get protection for your properties.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTextComponet text="<p><span>FREE</span> Monitored Home Security System!</p>" /> 
        </>
    );
};

export default Home;
