const ThankYou = () => {
    return (
        <>
            <section className="thank_you_blk">
                <div className="container-xxl">
                    <div className="thank_you_content" data-aos="fade-up">
                        <h1>Thank you for your interest in our products and services!</h1>
                        <div className="success_icon">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        <p>A representative will be in contact with you shortly. If you have any questions prior to meeting with the representative, you can contact us at <a href='tel:16319407595'>(631) 940-7595</a>.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThankYou;
