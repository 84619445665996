import React, { useEffect } from 'react';
import FooterTextComponet from '../../components/common/FooterTextComponet';
import LeadForm from '../../components/common/LeadForm';
import PageTitleComponent from '../../components/PageTitleComponent';
import Accordion from 'react-bootstrap/Accordion';
const CameraSystems = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageTitleComponent title="Camera Systems - Safe Circuits" />
            <section className="inner_hero_blk camera_systems_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>Camera Systems</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner_main_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="sidebar_wrap">
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>FREE On-Site Security Evaluation</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap">
                                            <LeadForm />
                                        </div>
                                        <div className="license_blk">
                                            <p><span>Licensed by N.Y.S. Department of State</span>: # 12000049143</p>
                                            <p><span>State of NJ</span>: # 34BA00181700</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap honeywell_links_wrap app_guide_wrap">
                                            <ul>
                                                <li><img src="../img/app-guide-1.jpg" alt="App Guide" /></li>
                                                <li><img src="../img/app-guide-2.jpg" alt="App Guide" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="inner_main_content">
                                <div className="main_content_blk">
                                    <h3>Video Surveillance Systems</h3>
                                    <p>Having a video surveillance system installed in your home or business can bring peace of mind and enable you to focus on Dome IP cameramore important things in your life, like being with your family or running your business.</p>
                                    <img src="../img/camera-sys-img.jpg" alt="House img" />
                                    <p>IP cameras connected to a Digital Video Recorder DVR or a Network video recorder NVR allow you to constantly record all activities and monitor your business or home from anywhere via a computer Pad or smartphone.</p>
                                    <p>A security camera system in a business will boost employee performance, customer service and production. It can protect you from frivolous law suits by customers and employees</p>
                                    <p>For home owners, legal battles with neighbors can be quickly settled reviewing the recording on the NVR/DVR System.</p>
                                    <p>DVR Camera systems including IP cameras, DVR and NVR have dramatically come down in price. Today a security camera surveillance system is very affordable to home owners and business of any size.</p>
                                    <p>Our knowledgeable video surveillance system advisors will evaluate your requirements and recommend a cost-effective security camera system. Our trained and courteous surveillance system installers will install the surveillance cameras and related system in an efficient manner with the least amount of intrusion in your home and disruption to your business.</p>
                                    <p>Safe Circuits is a video surveillance system installer based out of Dix Hills NY , serving Melville, Commack, and other areas in Nassau , Suffolk and Queens County.</p>
                                </div>
                                <div className="faq_box">
                                    <div className="faq_sec_title">
                                        <h3>Frequently Asked Questions</h3>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Can I view my cameras online?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, you will be able to monitor your property online through the use of Internet Protocol (IP) camera systems. With IP camera systems, you can keep months of security footage on a hard drive and not have to worry about overwriting tapes.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>What camera system do I need?</Accordion.Header>
                                            <Accordion.Body>
                                                When it comes to surveillance, each business or home has different needs and requirements so surveillance is not a one size fits all plan. Our friendly staff will gladly go over what you need to install for camera security on your property.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>What types of cameras are available?</Accordion.Header>
                                            <Accordion.Body>
                                                Because surveillance is needed in different types of environments, Safe Circuits offers a wide variety of cameras to you. Some examples are Day-Night, wireless, fixed IP, HDTV IP, megapixel IP, CCTV, 4 camera, and 8 camera systems.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTextComponet text="<p>Keep an eye on your property and have recorded evidence.</p>" />
        </>
    );
};

export default CameraSystems;
