const PaymentThankYou = () => {
    return (
        <>
            <section className="thank_you_blk">
                <div className="container-xxl">
                    <div className="thank_you_content" data-aos="fade-up">
                        <h1>Thank You!</h1>
                        <div className="success_icon">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        <p>Your payment has been successfully processed.</p>
                        <p>Thank you once again for choosing Safe Circuits. We look forward to serving you!</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PaymentThankYou;
