import React, { useEffect } from 'react';
const FooterTextComponet = (props: any) => {
  return (
    <>
      <section className="info_blk">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="info_ad_box">
                <div dangerouslySetInnerHTML={{ __html: props.text }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterTextComponet;
