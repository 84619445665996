import React, { useEffect } from 'react';
import LeadForm from '../../components/common/LeadForm';
import FooterTextComponet from '../../components/common/FooterTextComponet';
import PageTitleComponent from '../../components/PageTitleComponent';
import Accordion from 'react-bootstrap/Accordion';
const HomeAutomation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageTitleComponent title="Home Automation - Safe Circuits" />
            <section className="inner_hero_blk home_automation_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>Home Automation</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner_main_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="sidebar_wrap">
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>FREE Monitored Home Security System!</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap">
                                            <LeadForm />
                                        </div>
                                        <div className="license_blk">
                                            <p><span>Licensed by N.Y.S. Department of State</span>: # 12000049143</p>
                                            <p><span>State of NJ</span>: # 34BA00181700</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Get More Info on Honeywell</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap honeywell_links_wrap">
                                            <ul>
                                                <li><a href="../img/Honeywell-Leviton-Z-wave-Products-Brochure.pdf" target="_blank">Honeywell Leviton</a></li>
                                                <li><a href="../img/Honeywell-Tuxedo.pdf" target="_blank">Honeywell Tuxedo</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="inner_main_content">
                                <div className="main_content_blk">
                                    <h3>Add Automation to your Systems</h3>
                                    <p>Technology is making leaps and bounds daily, so why not make it a part of your daily life? Enjoy convenience while making your home or business comfortable and safe.</p>
                                    <img src="../img/home-automation-img.jpg" alt="House img" />
                                    <p>By forming a network of all your individual devices, you can make your life a lot simpler. Managing everything under one system can free up more time for you to spend efficiently. By conglomerating all your systems together, you can also potentially save a significant amount on utilities and future bills.</p>
                                    <p>Using automated systems can also add an aspect of fun to your life. Not only is it entertaining, but modern technology can also make your life more comfortable with personal preference settings.</p>
                                    <p><strong>FREE</strong> Monitored Home Security System!</p>
                                </div>
                                <div className="faq_box">
                                    <div className="faq_sec_title">
                                        <h3>Frequently Asked Questions</h3>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>What kind of intercoms can you install?</Accordion.Header>
                                            <Accordion.Body>
                                                Intercoms that we offer you are both wired and wireless. They include two way, doorbell, baby monitor, video, commercial, PA, and gate intercoms. We also offer intercoms for access control systems and can install music systems as well.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Can I get automated systems for lighting?</Accordion.Header>
                                            <Accordion.Body>
                                                With Safe Circuits Inc, you can definitely get automated systems for lighting in your home. With our services, you can control the lighting in your home from anywhere in the world with the touch of a button on your mobile phone.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Can I regulate the temperature of my home?</Accordion.Header>
                                            <Accordion.Body>
                                                You can control your thermostat from anywhere with our automated thermostat control system. Whether it be from bed or from the airport, you can change the temperature easily. You can also receive alerts if the temperature is too high or too low.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="main_content_blk more_info_blk">
                                    <h3><a href="../img/totalconnect_help.pdf" target="_blank">Totally Connect Help</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTextComponet text="<p>Automate your systems to add convenience and safety to your life.</p>" />
        </>
    );
};

export default HomeAutomation;
