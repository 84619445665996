import { useState, useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

const Payment = (props: any) => {
    const [step, setStep] = useState(1);
    const [customerData, setCustomerData] = useState([]);
    const gotoStep = (stepNo: number) => {
        setStep(stepNo);
    }
    useEffect(() => {
        if(step == 1){
            document.body.classList.remove('payment_summary');
            document.body.classList.add('payment_link');
        } else if(step == 2){
            document.body.classList.remove('payment_link');
            document.body.classList.add('payment_summary');
        }
    }, [step]);
    return (
        <>
            <div className="content">
                <div className="container-xxl">
                    <div className="bs-stepper linear">
                        <div className="bs-stepper-header" role="tablist">
                            <div className={`${step == 1 ? 'step active' : 'step'} step1 completed`} data-target="#step1">
                                <button type="button" className="step-trigger pl-0" role="tab" aria-controls="step1" id="step1-trigger" aria-selected={step == 1 ? true : false} disabled={step < 1 ? true : false}>
                                    <span className="bs-stepper-circle">1</span>
                                    <span className="bs-stepper-label">Account Details<span className='step_label'>(Step 1/2)</span></span>
                                </button>
                            </div>
                            <div className={`${step == 2 ? 'step active' : 'step'} step2`} data-target="#step2">
                                <button type="button" className="step-trigger pl-0" role="tab" aria-controls="step2" id="step2-trigger" aria-selected={step == 2 ? true : false} disabled={step < 2 ? true : false}>
                                    <span className="bs-stepper-circle">2</span>
                                    <span className="bs-stepper-label">Payment Completion<span className='step_label'>(Step 2/2)</span></span>
                                </button>
                            </div>
                        </div>
                        <div className="bs-stepper-content p-0">
                            <div id="step1" className={step == 1 ? 'content active dstepper-block' : 'content'} role="tabpanel" aria-labelledby="step1-trigger">
                                <Step1 gotoStep={gotoStep} setCustomerData={setCustomerData} />
                            </div>
                            <div id="step2" className={step == 2 ? 'content active dstepper-block' : 'content'} role="tabpanel" aria-labelledby="step2-trigger">
                                <Step2 gotoStep={gotoStep} customerData={customerData} setCustomerData={setCustomerData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
