import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-bootstrap/Fade';
const Footer = () => {
  const [open0, setOpen0] = useState(window.screen.height > 768 ? true : false);
  const [open1, setOpen1] = useState(window.screen.height > 768 ? true : false);
  const [open2, setOpen2] = useState(window.screen.height > 768 ? true : false);
  const [open3, setOpen3] = useState(window.screen.height > 768 ? true : false);
  const todayDate = new Date();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setOpen0(true);
        setOpen1(true);
        setOpen2(true);
        setOpen3(true);
      } else {
        setOpen0(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className={`footer_menu_blk${open0 ? ' open_footer' : ''}`}>

                <div className="footer_menu_title" onClick={() => { setOpen0(!open0); setOpen1(false); setOpen2(false); setOpen3(false); }} aria-controls="fmenu0" aria-expanded={open0}>
                  <h3>Main Menu</h3>
                  <svg width={8} height={8} role="img" aria-labelledby="open_submenu_6 open_submenu_6_desc" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><title id="open_submenu_6">Open Submenu</title><desc id="open_submenu_6_desc">Open Submenu Icon</desc><path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 3.77909 7.82091 3.6 7.6 3.6L4.4 3.6L4.4 0.4C4.4 0.179086 4.22091 -1.65189e-07 4 -1.74846e-07C3.77909 -1.84502e-07 3.6 0.179086 3.6 0.4L3.6 3.6L0.4 3.6C0.179086 3.6 -1.65189e-07 3.77909 -1.74846e-07 4C-1.84502e-07 4.22091 0.179086 4.4 0.4 4.4L3.6 4.4L3.6 7.6C3.6 7.82091 3.77909 8 4 8C4.22091 8 4.4 7.82091 4.4 7.6L4.4 4.4L7.6 4.4C7.82091 4.4 8 4.22091 8 4Z" fill="#EE3B33" /></svg>
                </div>

                <Fade in={open0}>
                  <ul className="footer_menu footer_mob_blk" id="fmenu0">
                    <li><Link to={"./alarm-systems"}>Alarm Systems</Link></li>
                    <li><Link to={"./camera-systems"}>Camera Systems</Link></li>
                    <li><Link to={"./home-automation"}>Home Automation</Link></li>
                    <li><Link to={"./contact-us"}>Contact Us</Link></li>
                    <li><Link to={"./help"}>Help</Link></li>
                  </ul>
                </Fade>

              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className={`footer_menu_blk${open1 ? ' open_footer' : ''}`}>
                <div className="footer_menu_title" onClick={() => { setOpen1(!open1); setOpen0(false); setOpen2(false); setOpen3(false); }} aria-controls="fmenu1" aria-expanded={open1}>
                  <h3>Keep In Touch</h3>
                  <svg width={8} height={8} role="img" aria-labelledby="open_submenu_6 open_submenu_6_desc" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><title id="open_submenu_6">Open Submenu</title><desc id="open_submenu_6_desc">Open Submenu Icon</desc><path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 3.77909 7.82091 3.6 7.6 3.6L4.4 3.6L4.4 0.4C4.4 0.179086 4.22091 -1.65189e-07 4 -1.74846e-07C3.77909 -1.84502e-07 3.6 0.179086 3.6 0.4L3.6 3.6L0.4 3.6C0.179086 3.6 -1.65189e-07 3.77909 -1.74846e-07 4C-1.84502e-07 4.22091 0.179086 4.4 0.4 4.4L3.6 4.4L3.6 7.6C3.6 7.82091 3.77909 8 4 8C4.22091 8 4.4 7.82091 4.4 7.6L4.4 4.4L7.6 4.4C7.82091 4.4 8 4.22091 8 4Z" fill="#EE3B33" /></svg>
                </div>
                <Fade in={open1}>
                  <div className="footer_content footer_mob_blk" id="fmenu1">
                    <p>Based out of Dix Hills NY , serving Melville, Commack, and other areas in Nassau , Suffolk and Queens County.</p>
                    <p><strong>For help and inquiries</strong> <br />
                      <a href="mailto:support@safecircuitsinc.com">support@safecircuitsinc.com</a><br />
                      <a href="tel:16319407595">(631) 940-7595</a></p>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className={`footer_menu_blk${open2 ? ' open_footer' : ''}`}>
                <div className="footer_menu_title" onClick={() => { setOpen2(!open2); setOpen0(false); setOpen1(false); setOpen3(false); }} aria-controls="fmenu2" aria-expanded={open2}>
                  <h3>Make a payment</h3>
                  <svg width={8} height={8} role="img" aria-labelledby="open_submenu_6 open_submenu_6_desc" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><title id="open_submenu_6">Open Submenu</title><desc id="open_submenu_6_desc">Open Submenu Icon</desc><path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 3.77909 7.82091 3.6 7.6 3.6L4.4 3.6L4.4 0.4C4.4 0.179086 4.22091 -1.65189e-07 4 -1.74846e-07C3.77909 -1.84502e-07 3.6 0.179086 3.6 0.4L3.6 3.6L0.4 3.6C0.179086 3.6 -1.65189e-07 3.77909 -1.74846e-07 4C-1.84502e-07 4.22091 0.179086 4.4 0.4 4.4L3.6 4.4L3.6 7.6C3.6 7.82091 3.77909 8 4 8C4.22091 8 4.4 7.82091 4.4 7.6L4.4 4.4L7.6 4.4C7.82091 4.4 8 4.22091 8 4Z" fill="#EE3B33" /></svg>
                </div>
                <Fade in={open2}>
                  {/*<div className="footer_content payment_cont footer_mob_blk" id="fmenu2">
                    <p>
                      <a href="https://safecircuitsinc.slomins.com/payment" target="_blank"><strong>Pay with Credit Card</strong></a><br />
                      <a href="https://safecircuitsinc.slomins.com/payment" target="_blank"><img src="../img/payment.png" alt="American Express MasterCard Visa Discover credit card logos" /></a>
                    </p> 
                  </div>*/}
                  <div className="footer_content payment_cont footer_mob_blk">
                    <p><a href="https://secure.usaepay.com/interface/epayform/yi47Y5AsR5kqQY0WY0wubb0XahWlQn92" target="_blank"><strong>Pay with Credit Card</strong></a><br />
                      <a href="https://secure.usaepay.com/interface/epayform/yi47Y5AsR5kqQY0WY0wubb0XahWlQn92" target="_blank"><img src="../img/payment.png" alt="American Express MasterCard Visa Discover credit card logos" /></a></p>
                    {/*<p><strong>Pay with PayPal</strong></p>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                      <input type="hidden" name="cmd" defaultValue="_s-xclick" />
                      <input type="hidden" name="hosted_button_id" defaultValue="T6HSC3UMRHRCY" />
                      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif" border={0} name="submit" alt="PayPal - The safer, easier way to pay online!" />
                      <img alt="" border={0} src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width={1} height={1} />
                </form>*/}
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className={`footer_menu_blk social_media_links${open3 ? ' open_footer' : ''}`}>
                <div className="footer_menu_title" onClick={() => { setOpen3(!open3); setOpen0(false); setOpen1(false); setOpen2(false); }} aria-controls="fmenu3" aria-expanded={open3}>
                  <h3>Social Media</h3>
                  <svg width={8} height={8} role="img" aria-labelledby="open_submenu_6 open_submenu_6_desc" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><title id="open_submenu_6">Open Submenu</title><desc id="open_submenu_6_desc">Open Submenu Icon</desc><path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 3.77909 7.82091 3.6 7.6 3.6L4.4 3.6L4.4 0.4C4.4 0.179086 4.22091 -1.65189e-07 4 -1.74846e-07C3.77909 -1.84502e-07 3.6 0.179086 3.6 0.4L3.6 3.6L0.4 3.6C0.179086 3.6 -1.65189e-07 3.77909 -1.74846e-07 4C-1.84502e-07 4.22091 0.179086 4.4 0.4 4.4L3.6 4.4L3.6 7.6C3.6 7.82091 3.77909 8 4 8C4.22091 8 4.4 7.82091 4.4 7.6L4.4 4.4L7.6 4.4C7.82091 4.4 8 4.22091 8 4Z" fill="#EE3B33" /></svg>
                </div>
                <Fade in={open3}>
                  <div className="footer_content footer_mob_blk" id="fmenu3">
                    <p><img src="../img/honeywell.png" alt="Honeywell Authorized Dealer logo" width={130} height={62} /></p>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/pages/Safe-Circuits-Inc/1444731325783839" target="_blank">
                          <svg width={11} height={20} viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="facebook-logo">
                              <path id="Path" d="M10.388 0.00416134L7.79888 0C4.89011 0 3.01034 1.9319 3.01034 4.92203V7.19141H0.407111C0.182162 7.19141 0 7.37409 0 7.59943V10.8875C0 11.1128 0.182369 11.2953 0.407111 11.2953H3.01034V19.5922C3.01034 19.8175 3.1925 20 3.41745 20H6.81392C7.03887 20 7.22103 19.8173 7.22103 19.5922V11.2953H10.2648C10.4898 11.2953 10.6719 11.1128 10.6719 10.8875L10.6732 7.59943C10.6732 7.49123 10.6302 7.38762 10.5539 7.31105C10.4777 7.23448 10.3739 7.19141 10.2659 7.19141H7.22103V5.26763C7.22103 4.34298 7.441 3.87358 8.64343 3.87358L10.3876 3.87295C10.6123 3.87295 10.7945 3.69027 10.7945 3.46514V0.411972C10.7945 0.187052 10.6125 0.00457747 10.388 0.00416134Z" fill="white" />
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/SafeCircuitsInc" target="_blank">
                          <svg width={22} height={18} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="twitter">
                              <path id="Path" d="M7.93615 18C7.01625 18 6.06941 17.923 5.11689 17.7686C3.43923 17.4965 1.74096 16.5892 1.2604 16.3175L0 15.6051L1.36459 15.1411C2.85626 14.6341 3.76366 14.3195 4.88692 13.8268C3.7622 13.2637 2.89538 12.2507 2.47811 10.9413L2.16066 9.94492L2.42098 9.98602C2.17413 9.7288 1.97921 9.46771 1.82811 9.23028C1.29075 8.38645 1.00624 7.35536 1.06678 6.47176L1.1265 5.60209L1.63011 5.80378C1.41766 5.38899 1.26413 4.94602 1.17389 4.48173C0.953981 3.34946 1.13803 2.14655 1.69227 1.09466L2.13096 0.262068L2.71766 0.990627C4.5732 3.2951 6.92342 4.66211 9.71281 5.0623C9.59888 4.25035 9.68425 3.46675 9.96681 2.76437C10.2958 1.94655 10.881 1.25306 11.6588 0.758736C12.5225 0.209885 13.57 -0.0560678 14.6082 0.00987499C15.7097 0.0798448 16.7098 0.506543 17.504 1.24467C17.8919 1.14047 18.1777 1.02922 18.564 0.878876C18.7964 0.788436 19.06 0.685746 19.3898 0.568291L20.605 0.135384L19.8125 2.47543C19.8648 2.4709 19.9187 2.46704 19.975 2.46452L21.2727 2.40378L20.5057 3.48756C20.4617 3.54964 20.4505 3.56726 20.435 3.59159C20.3731 3.6879 20.2962 3.80771 19.2434 5.26113C18.9798 5.62508 18.8482 6.09909 18.8727 6.5961C18.966 8.48478 18.7419 10.1936 18.2061 11.6749C17.6994 13.0763 16.9142 14.2787 15.8726 15.2487C14.5837 16.4488 12.9401 17.2704 10.9873 17.6906C10.0295 17.8966 9.00082 18 7.93615 18Z" fill="white" />
                            </g>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ft_btm_content">
                <div className="copyright_blk">
                  <p>© {todayDate.getFullYear()} Safe Circuits. All Rights Reserved.</p>
                </div>
                <div className="legal_links">
                  <ul>
                    <li><Link to={"./privacy-policy"}>Privacy Policy</Link></li>
                    <li><Link to={"./cookie-policy"}>Cookie Policy</Link></li>
                    <li><Link to={"./conditions-of-Use"}>Conditions of Use</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
