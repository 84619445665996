import React from 'react';
import Modal from 'react-bootstrap/Modal';

const SuccessMsgMdl = (props) => { 
  return (
    <Modal size="md" className='email_sms_popup' aria-labelledby="contained-modal-title-vcenter" show={props.modalShow} onHide={() => props.handleCloseSMSMdl()} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.resp.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='success_tick'>
          <img src='../img/tick_mark.svg' alt='Images' />
        </div>
        {(props.resp.type == "1") ? 
            <>
              <p>An email has been sent to <span>{(props.resp.email) ? props.resp.email : ""}</span> successfully.</p>
              <p>Please check the Email to get the list of account numbers associated to this Email. Then enter the Account number for which you need to make a payment in previous screen.</p>
            </>
            : 
            <>
              <p>An SMS has been sent to <span>{(props.resp.phone) ? props.resp.phone : "xxx-xxx-xxxx"}</span> successfully.</p>
              <p>Please check the SMS to get the list of account numbers associated to this Phone Number. Then enter the Account number for which you need to make a payment in previous screen.</p>
            </>
        }
        <div className='ok_btn_blk'>
          <button type="button" onClick={() => { props.handleCloseSMSMdl() }} className="btn btn-primary red_btn">Ok</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessMsgMdl;
