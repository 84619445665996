import React, { useEffect } from 'react';
import FooterTextComponet from '../../components/common/FooterTextComponet';
import PageTitleComponent from '../../components/PageTitleComponent';
import LeadForm from '../../components/common/LeadForm';
import Accordion from 'react-bootstrap/Accordion';
const AlarmSystems = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageTitleComponent title="Alarm Systems - Safe Circuits" />
            <section className="inner_hero_blk alarm_systems_pg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="inner_hero_txt_blk">
                                <h1>Alarm Systems</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="inner_main_blk">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="sidebar_wrap">
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>FREE Monitored Home Security System!</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap">
                                            <LeadForm />
                                        </div>
                                        <div className="license_blk">
                                            <p><span>Licensed by N.Y.S. Department of State</span>: # 12000049143</p>
                                            <p><span>State of NJ</span>: # 34BA00181700</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Safe Circuits Video</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap video_wrap">
                                            <video width="100%" height="auto" poster="../img/video-poster.png" controls preload="auto" playsInline>
                                                <source src="../img/safe-circuits-n.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar_item">
                                    <div className="sidebar_heading"><h3>Get More Info on Honeywell</h3></div>
                                    <div className="side_blk_content">
                                        <div className="lead_form_wrap honeywell_links_wrap">
                                            <ul>
                                                <li><a href="../img/5800-Series-Sensors-Evolution-of-Wireless-Brochure.pdf" target="_blank">Honeywell Wireless Transmitters</a></li>
                                                <li><a href="../img/Honeywell-Tuxedo.pdf" target="_blank">Honeywell Tuxedo</a></li>
                                                <li><a href="../img/Honeywell-VISTA-Plus-Series-Information-for-Security-Dealers.pdf" target="_blank">Honeywell VISTA</a></li>
                                                <li><a href="../img/Honeywell-Keypads.pdf" target="_blank">New 6100 Series Keypads</a></li>
                                                <li> <a href="../img/Honeywells-Keypad-Family.pdf" target="_blank">Keypads</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="inner_main_content">
                                <div className="main_content_blk">
                                    <h3>Residential Alarm Systems</h3>
                                    <p>Alarm systems are a must for your home. Don’t go through the hassle and stress of a home invasion when you could’ve easily prevented it – install an alarm system by Safe Circuits to stop this scenario from happening.</p>
                                    <img src="../img/alarm-sys-img.jpg" alt="House img" />
                                    <p>Not only will the alarm system protect your home from burglary and damages, but you can protect your family as well. Break-ins can potentially be traumatic, so save your loved ones from the emotional stress. A residential alarm system can give you peace of mind if you have to leave your house for an extended time.</p>
                                    <p>With quick response times from our monitoring station and the most current technology used, this can make all the difference in the protection of your home and family.</p>
                                </div>
                                <div className="main_content_blk">
                                    <h3>Commercial Alarm Systems</h3>
                                    <p>Prevent losses and the hassle of insurance claims by installing a commercial alarm system in your office, warehouse and other business premises.</p>
                                    <p>Whether you are interested in a wireless or hardwired intrusion/burglar alarm system, camera security surveillance system fire detection systems, or all of the above. Safe Circuits has a solution for you.</p>
                                    <p>Safeguard your business and commercial property with a security system.</p>
                                    <p>For 29 years, Safe Circuits has been installing residential and commercial alarm systems in Dix Hills NY, Melville, Commack, and all of Nassau, Suffolk and Queens County.</p>
                                </div>
                                <div className="faq_box">
                                    <div className="faq_sec_title">
                                        <h3>Frequently Asked Questions</h3>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>What residential alarm systems are available?</Accordion.Header>
                                            <Accordion.Body>
                                                Residential alarm systems include intruder, smoke, heat, and carbon monoxide detection. There are panic and medical alert options, as well as the capability to detect the proper authorities for your type of emergency.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>What commercial alarm systems are available?</Accordion.Header>
                                            <Accordion.Body>
                                                All types of residential alarm systems are available commercially as well. Because we understand that businesses have different needs, you have the option of a FREE on-site security evaluation so your exact needs for security can be determined.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>What is Total Connect?</Accordion.Header>
                                            <Accordion.Body>
                                                Total Connect is a remote service available on mobile phones as an app. You can receive important alerts and manage your security systems while you're away from your home or business.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <div className="main_content_blk more_info_blk">
                                    <h3>For more information, please visit:</h3>
                                    <ul>
                                        <li><a href="https://rs.alarmnet.com/totalconnect2" target="_blank">https://rs.alarmnet.com/totalconnect2</a></li>
                                        <li><a href="../img/totalconnect_help.pdf">https://rs.alarmnet.com/TotalConnect2/Content/pdf/totalconnect_help.pdf</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTextComponet text="<p>Install the first line of defense against intruders for your property.</p>" />
        </>
    );
};

export default AlarmSystems;
