import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route,useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Main from '@modules/main/Main';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';
import PageNotFound from '@app/pages/Page404';
import ThankYou from '@app/pages/ThankYou';
import PaymentThankYou from '@app/pages/PaymentThankYou';
import Home from '@pages/Home';
import HomeV2 from '@pages/HomeV2';
import Payment from '@pages/payment-page/Payment';
import { PrivacyPolicy, AlarmSystems, CameraSystems, HomeAutomation, Help, ContactUs, ConditionsOfUse, CookiePolicy } from './pages/static-page';
const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/home-v2" element={<HomeV2 />} />
          <Route path="/alarm-systems" element={<AlarmSystems />} />
          <Route path="/camera-systems" element={<CameraSystems />} />
          <Route path="/home-automation" element={<HomeAutomation />} />
          <Route path="/help" element={<Help />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/conditions-of-use" element={<ConditionsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          {<Route path="/thank-you" element={<ThankYou />} /> }
          {<Route path="/payment-thank-you" element={<PaymentThankYou />} /> }
          <Route path="/payment" element={<Payment />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        containerId="toaster" 
      />
    </BrowserRouter>
  );
};

export default App;
